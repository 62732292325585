import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const saveSvgAsPng = require('save-svg-as-png');

export function useSaveAsPng(element: SVGSVGElement | null, fileName = 'diagram', scale = 1) {
  const saveAsPng = useCallback(() => {
    if (element) {
      const clonedElement = element.cloneNode(true);
      closeImageTags(clonedElement);
      const imageOptions = {
        scale: (1 / scale) * 1.5,
        encoderOptions: 1,
        backgroundColor: 'white',
        left: element.viewBox.baseVal.x,
        top: element.viewBox.baseVal.y,
      };
      const diagramName = fileName;
      saveSvgAsPng.saveSvgAsPng(clonedElement, `${diagramName}.png`, imageOptions);
    }
  }, [element, fileName, scale]);

  return { saveAsPng };
}

function closeImageTags(element: Node) {
  element.childNodes.forEach((c) => {
    if (isImageTag(c)) {
      element.removeChild(c);
    } else {
      closeImageTags(c);
    }
  });
}
function isImageTag(c: ChildNode): c is Element {
  return 'tagName' in c
    && typeof c.tagName === 'string'
    && c.tagName.toUpperCase() === 'IMG';
}
